import apiClient from '../../axios'

export default class AccountsRejectionsService {
  static async getAgentRejections(reqData) {
    try {
      const response = await apiClient.get('/admin/accounts/rejections', { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching agent rejections:', error)
      throw error
    }
  }

  static async getAgentRejectionStats(reqData) {
    try {
      const response = await apiClient.get('/admin/accounts/rejections/analytics/digits', { params: reqData })
      return response
    } catch (error) {
      console.error('Error fetching agent rejection stats:', error)
      throw error
    }
  }
}
